*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  background: url('./Images/polska_bg.avif');
  min-height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}