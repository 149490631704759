.home-page {
    width: 1160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #ffffff90;
    padding: 40px;
    border-radius: 20px;

    .title {
        font-size: 50px;
    }

    .description{
        max-width: 600px;
        font-size: 16px;
        margin-top: 20px;
    }

    .download-btn {
        margin-top: 40px;
        padding: 10px 30px;
        background-color: #ffffff;
        border-radius: 10px;
        min-height: 48px;
        font-size: 20px;
        font-weight: 600;
        color: #000000;
        transition: all 600ms;
        cursor: pointer;
        border: 2px solid transparent;

        &:hover{
            background-color: #F60000;
            color: #000000;
            transform: scale(1.02);
            border: 2px solid #ffffff;
        }
    }
    .privacy-policy-link{
        margin-top: 40px;
        font-size: 16px;
        text-decoration: none;
        color: #000000;
        font-weight: 500;
        cursor: pointer;
        transition: all 600ms;

        &:hover{
            color: #ffffff;
            transform: scale(1.02);

        }
    }
}