.privacy-policy{
    width: 1160px;
    background-color: #ffffff90;
    padding: 40px;
    border-radius: 20px;
    h1{
        text-align: center;
    }
    h2{
        margin-top: 20px;
    }
    p{
        margin-top: 20px;
    }
    .back-link{
        display: block;
        width: 100%;
        margin-top: 20px;
        text-align: center;
        text-decoration: none;
        color: #F60000;
        transition: all 600ms;

        &:hover{
            color: #000000;
            transform: scale(1.02);
        }

    }
}